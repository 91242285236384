import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaPlus, FaMinus } from "react-icons/fa";
import './../css/component/accordion.css';
// import 'react-accessible-accordion/dist/fancy-example.css';

const FaqList = [
    {
        question: 'Che cos\'è E-Fuel Card?',
        answer: 'L\'applicazione, progettata esclusivamente per le stazioni di servizio, offre un sofisticato sistema di gestione delle schede carburante, consentendo ai gestori di effettuare una gestione efficiente e precisa delle transazioni dei clienti. L\'applicazione facilita il monitoraggio e il controllo delle schede carburante, ottimizzando così l\'esperienza di gestione per le stazioni di servizio e migliorando la soddisfazione della clientela.'
    },
    {
        question: 'Come funzionano le Card NFC?',
        answer: 'Quando un cliente si reca alla stazione di rifornimento, l\'esperienza di rifornimento è semplice e altamente sicura. Il cliente deve semplicemente passare la sua card NFC sull\'app dedicata del gestore presente presso la pompa di rifornimento e digitare il suo PIN. Questa azione rapida e intuitiva serve a certificare l\'avvenuto rifornimento.'
    },
    {
        question: 'Cosa succede quando inserisco il PIN?',
        answer: 'Una volta che la card NFC è stata passata sull\'app del gestore e digitato il PIN, il sistema verifica e registra automaticamente l\'avvenuto rifornimento associato al cliente specifico. Questo processo di verifica assicura che solo i clienti autorizzati possano usufruire del servizio di rifornimento.'
    },
    {
        question: 'Posso esportare la lista dei rifornimenti giornalieri?',
        answer: 'Una caratteristica distintiva del sistema è la sua capacità di esportare dati in un formato compatibile con i gestionali aziendali più diffusi sul mercato. Questo consente una perfetta integrazione dei dati di rifornimento con i processi aziendali esistenti. Gli utenti possono selezionare un range di tempo specifico e esportare agevolmente i dati delle transazioni, assicurandosi che siano compatibili con qualsiasi software gestionale utilizzato dalla stazione di servizio. Questa funzionalità semplifica notevolmente la gestione contabile e la reportistica aziendale.'
    }
]


class Accordion1 extends Component {
    
    render() {
        return (
            <Accordion preExpanded={'0'}>
                {FaqList.map((val, i)=>(
                    <AccordionItem className="faq" key={i}>
                        <AccordionItemHeading className="faq-header">
                            <AccordionItemButton className="btn btn-link">
                                <span className="icon">
                                    <FaPlus className="fa-plus" />
                                    <FaMinus className="fa-minus" />
                                </span>
                                <span className="text">{val.question}</span>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='faq-body'>
                            <p>
                                {val.answer}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        )
    }
}

export default Accordion1;