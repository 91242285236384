import React, { Component } from 'react';
import SectionHeader from './../component/section-header';
import { FaRegListAlt, FaChartLine } from "react-icons/fa";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { FiCheckCircle } from "react-icons/fi";
import './../css/screenshot.css';

// section header content const
const title = <h3 className="mb--15">E-Fuel software,<br /> Layout user friendly</h3>;
const subtitle = <h6 className="sub-title">L'applicazione è sincronizzata in tempo reale con la Dashboard principale del gestore</h6>;
const alignment = 'section-header text-lg-left pb--40';

// section header 2 content const
const title2 = <h3 className="mb--15">Dashboard <br />principale per la gestione </h3>
const subtitle2 = <h6 className="sub-title">Il software si avvale di un'infrastruttura solida per la gestione completa di tutte le operazioni</h6>
const alignment2 = 'section-header text-lg-left pb--20';

// section header 3 content const
const title3 = <h3 className="mb-3">E-Fuel software,<br /> Layout user friendly</h3>
const subtitle3 = <h6 className="sub-title mb--30">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. </h6>
const alignment3 = 'section-header text-lg-left';

const ssInfoList1 = [
    {
        icon: <FaRegListAlt />,
        title: 'Aggiorna i prezzi',
        description: 'Controllo giornaliero sincronizzazione prezzi '
    },
    {
        icon: <FaChartLine />,
        title: 'Lista rifornimenti',
        description: 'Dettagli ultimi rifornimenti per distributore '
    },
    {
        icon: <AiOutlineThunderbolt />,
        title: 'Esporta report',
        description: 'Creazione file csv per i gestionali più popolari'
    },
]

const ssInfoList2 = [
    {
        icon: <FiCheckCircle />,
        description: 'Facile consultazione con filtri per la ricerca'
    },
    {
        icon: <FiCheckCircle />,
        description: 'Lista Card e assegnazione cliente in tempo reale'
    },
    {
        icon: <FiCheckCircle />,
        description: 'Gestione Multi distributore con storico rifornimenti '
    },
]

class ScreenShot extends Component {
    render() {
        return (
            <section id="screenshot" className={`${this.props.screenshotStyle ? this.props.screenshotStyle : ""}`}>
                <div className="container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50">
                    <div className="row">
                        <div className="col-lg-5 order-lg-last">
                            <div className="screenshot-content text-center text-lg-left">
                                <SectionHeader 
                                    title={ title } 
                                    subtitle={ subtitle }
                                    alignment={alignment}
                                />

                                <div className="screenshot-info-container-style1 hover-effect-parent1 text-left">
                                    {ssInfoList1.map( (val , i) => (
                                        <div className="screenshot-info-item d-flex flex-wrap" key={i}>
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h6 className="font-weight-bold mb-2 mt-0">{val.title}</h6>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    ))} 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 order-lg-first pr_lg--100">
                            <div className="screenshot-image image1">
                                <img
                                    src="assets/images/banner/img2.png" 
                                    alt="screenshot"
                                 />
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="screenshot-content text-center text-lg-left">
                                <SectionHeader 
                                    title={ title2 } 
                                    subtitle={ subtitle2 }
                                    alignment={alignment2}
                                />
                                <div className="screenshot-info-container-style2 text-left">
                                    {ssInfoList2.map( (val , i) => (
                                        <div className="screenshot-info-item-style2 d-flex flex-wrap" key={i}>
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 pl_lg--100">
                            <div className="screenshot-image image2">
                                <img 
                                    src="assets/images/screenshot/dash2.png" 
                                    alt="images"
                                />
                            </div>
                        </div>  
                    </div>
                </div>

                
            </section>
        )
    }
}

export default ScreenShot;