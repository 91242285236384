import React, { Component } from 'react';
import { Swiper, Pagination } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import './../css/component/testimonial.css';
import 'swiper/css/swiper.css';

const testimonialList = [
    {
        imagUrl: "01",
        imageAlt: 'author1',
        text: 'Sono un gestore di stazione di servizio da molti anni e ho recentemente adottato questa applicazione per gestire le schede carburante dei miei clienti. Non posso essere più soddisfatto della decisione presa. L\'applicazione ha semplificato enormemente la mia vita quotidiana. La gestione centralizzata delle transazioni è stata un cambiamento incredibile, rendendo tutto più efficiente. La sicurezza è al top con controlli d\'accesso avanzati, e i report dettagliati mi hanno dato una visione chiara dei consumi dei clienti. Un must per ogni stazione di servizio!',
        name: 'Giuseppe',
        designation: 'Gestore'
    },
    {
        imagUrl: "02",
        imageAlt: 'author2',
        text: 'Questa applicazione è un game changer per la mia attività di stazione di servizio. Ho visto un miglioramento immediato nella gestione delle schede carburante dei clienti. La facilità d\'uso è impressionante, e i miei dipendenti hanno apprezzato il passaggio a questo sistema. I controlli di sicurezza sono eccellenti e mi sento al sicuro sapendo che solo il personale autorizzato può accedere al sistema. I report e le analisi avanzate sono incredibili, consentendomi di ottimizzare la mia operatività. Consiglio vivamente questa applicazione a tutti i proprietari di stazioni di servizio.',
        name: 'Francesco',
        designation: 'Gestore'
    }
]

const params = {
    // Provide Swiper class as props
    Swiper,
    containerClass: 'axsis-testimonial-container',
    spaceBetween: 30,
    slidesPerView: 1,
    loop: true,
    // Add modules you need
    modules: [Pagination],
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        }
    }
  }

class Testimonial extends Component {
    render() {
        return (
            <ReactIdSwiperCustom {...params}>
                {testimonialList.map((val , i) => (
                    <div className="swiper-slide" key={i}>
                        <div className="testimonial-body d-flex flex-wrap justify-content-between">
                            
                            <div className="testimonial-content">
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <p className="text">{val.text}</p>
                                <h6 className="name">{val.name}</h6>
                                <p className="designation mb-0">{val.designation}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </ReactIdSwiperCustom>
        )
    }
}

export default Testimonial;