import React, { Component } from 'react';
import { FaCloudUploadAlt, FaRegCommentAlt, FaRegImages, FaLaptopCode, FaRegHandPaper, FaChartLine } from "react-icons/fa";
import SectionHeader from './../component/section-header';
import './../css/feature.css';

const FeatureList = [
    {
        icon: <FaLaptopCode />,
        title: 'Gestione Centralizzata',
        description: 'Piattaforma centralizzata per la gestione delle transazioni delle schede carburante dei clienti.'
    },
    {
        icon: <FaRegHandPaper />,
        title: 'Controllo degli Accessi',
        description: 'Sicurezza e controllo degli accessi per garantire l\'accesso solo a personale autorizzato .'
    },
    {
        icon: <FaChartLine />,
        title: 'Report in tempo reale',
        description: 'Strumenti di reportistica, analisi e informazioni utili per la pianificazione e la gestione delle risorse.'
    },
]

// header section content
const title = <h3 className="mb--25">Caratteristiche principali</h3>;
const subtitle = <h6><b>E-FuelCard</b> progettata esclusivamente per le stazioni di servizio</h6>;
const alignment = 'section-header text-center pb--60';

class Feature extends Component {
    render() {
        return (
            <section id="feature" className={`${this.props.featureStyle ? this.props.featureStyle : ""}`}>
                <div className="container">
                    <SectionHeader 
                        title={ title } 
                        subtitle={ subtitle }
                        alignment={alignment}
                    />
                </div>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-start">
                        {FeatureList.map( (val , i) => (
                            <div className="col-md-6 col-lg-4 mb--30 mb-lg-0" key={i}>
                                <div className="feature-item text-left">
                                    <div className="icon-box">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <img 
                                            src="assets/images/feature/feature-dot-shape.png" 
                                            alt="dot shape"
                                        />
                                    </div>
                                    <h5>{val.title}</h5>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}

export default Feature;